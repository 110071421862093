import React from 'react'
import { AddLink, ArticleStatus, ErrorBoundary, PageInfo, SiteLink } from '@app/shared'
import { PageHeader, Skeleton, Space, Tabs } from 'antd'
import { useNews } from '@app/hooks/query/news'
import { NewsPageEditor, NewsTable } from '@app/pages/news-page/components'

interface props {}

const NewsPage: React.FC<props> = () => {
  const { isLoading, isError, data: response } = useNews()
  if (isLoading) return <Skeleton active />
  if (isError) return <ErrorBoundary />

  const { data, ...pagination } = response!

  return (
    <React.Fragment>
      <PageInfo title={'Новости'} />
      <PageHeader title={'Новости'} extra={[<AddLink key={'1'} route={'addNews'} />]} />
      <Tabs
        tabBarExtraContent={{
          right: <SiteLink key={1} entityType={'press-centr/novosti'} />,
        }}
      >
        <Tabs.TabPane key={'1'} tab={'Таблица'}>
          <NewsTable news={data} pagination={pagination} />
        </Tabs.TabPane>
        <Tabs.TabPane key={'2'} tab={'Редактор страницы'}>
          <NewsPageEditor />
        </Tabs.TabPane>
      </Tabs>
    </React.Fragment>
  )
}

export default NewsPage
