import React from 'react'
import { Badge, Tag } from 'antd'

interface props {
  status: 'draft' | 'published'
}

const ArticleStatus: React.FC<props> = ({ status }) => {
  return (
    <Tag color={status === 'draft' ? 'red' : 'green'}>
      {status === 'draft' ? 'Черновик' : 'Опубликована'}
    </Tag>
  )
}

export default ArticleStatus
