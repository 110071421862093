import React from 'react'
import { Badge, Button, message } from 'antd'
import { idType } from '../../interfaces'
import { useMutation } from 'react-query'
import { queryClient } from '../../index'

interface props {
  id: idType
  status: 'draft' | 'published'
  queryKey: string | string[]
  api: (id: idType) => Promise<any>
}

const PublishArticle: React.FC<props> = ({ id, status, queryKey, api }) => {
  const { mutate, isLoading } = useMutation(api, {
    onSuccess: () => {
      message.success('Статус успешно изменен')
      queryClient.invalidateQueries(queryKey)
    },
    onError: (e: any) => {
      message.error(e.response.data.message)
    },
  })
  const handlePublish = () => mutate(id)
  return (
    <Button onClick={handlePublish} disabled={status === 'published'} loading={isLoading}>
      Опубликовать
    </Button>
  )
}

export default PublishArticle
