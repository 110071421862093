import React from 'react'
import { NEWS_KEY, useSingleNews } from '@app/hooks/query/news'
import { useParams } from 'react-router-dom'
import { Card, Col, Divider, Row, Skeleton, Space, Typography } from 'antd'
import {
  ArticleStatus,
  EditLink,
  ErrorBoundary,
  ImageWithFallback,
  PageInfo,
  PublishArticle,
  SecondaryText,
  SeoCard,
  SiteLink,
  SpaceWithDivider,
  Subtitle,
} from '@app/shared'
import { NewsService } from '@api/index'

const { Title } = Typography

interface props {}

const SingleNewsPage: React.FC<props> = () => {
  const { id } = useParams<{ id: string }>()
  const { data, isLoading, isError } = useSingleNews(id)

  if (isLoading) return <Skeleton active />
  if (isError) return <ErrorBoundary />

  const { page, ...info } = data!

  return (
    <React.Fragment>
      <PageInfo title={info.title} />
      <Row gutter={[32, 32]}>
        <Col xs={24} xl={15}>
          <Card
            title={'Общая информация'}
            extra={[
              <Space key={'1'}>
                <PublishArticle
                  id={info.id}
                  status={info.status}
                  api={NewsService.publish}
                  queryKey={[NEWS_KEY, id]}
                />
                <EditLink route={'news'} id={id} />
              </Space>,
            ]}
          >
            <ImageWithFallback src={info.image_path} width={500} />
            <Title level={2}>{info.title}</Title>
            <SpaceWithDivider>
              <SecondaryText>ID новости: {id}</SecondaryText>
              <SecondaryText>Дата публикации: {info.date}</SecondaryText>
              <SecondaryText>Slug: {info.slug}</SecondaryText>
              <ArticleStatus status={info.status} />
            </SpaceWithDivider>
            <Divider />
            <Subtitle>Краткое описание</Subtitle>
            <div dangerouslySetInnerHTML={{ __html: page.short_content }} />
            <Divider />
            <Subtitle>Контент</Subtitle>
            <div dangerouslySetInnerHTML={{ __html: page.content }} />
          </Card>
        </Col>
        <Col xs={24} xl={9}>
          <SeoCard title={page.title} description={page.description} keywords={page.keywords} />
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default SingleNewsPage
