import { idType } from '@app/interfaces'
import makeRequest from '@api/makeRequest'
import { history } from '@api/history'
import queryString from 'query-string'
import { IArticle } from '@app/interfaces/articles'

const url = '/api/articles'

class ArticlesService {
  getAll() {
    return makeRequest({
      url,
      params: queryString.parse(history.location.search),
    })
  }

  getById(id: idType) {
    return makeRequest({
      url: url + '/' + id,
    })
  }

  create(data: IArticle) {
    return makeRequest({
      url,
      method: 'post',
      data,
    })
  }

  update(data: { article: IArticle; id: idType }) {
    return makeRequest({
      url: url + '/' + data.id + '?_method=PUT',
      method: 'post',
      data: data.article,
    })
  }

  delete(id: idType) {
    return makeRequest({
      url: url + '/' + id,
      method: 'delete',
    })
  }

  import(formData: FormData) {
    return makeRequest({
      url: url + '/import',
      method: 'post',
      data: formData,
    })
  }

  export() {
    return makeRequest({
      url: url + '/export',
      method: 'get',
      responseType: 'blob',
    })
  }
  publish(id: idType) {
    return makeRequest({
      url: url + '/' + id + '/publish',
      method: 'post',
    })
  }
}

export default new ArticlesService()
