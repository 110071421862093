import React from 'react'
import { Divider, Space, Table } from 'antd'
import { IArticles } from '@app/interfaces/articles'
import { idType, IPagination } from '@app/interfaces'
import {
  ArticleStatus,
  ArticleTags,
  CategoryLink,
  DeleteModal,
  EditLink,
  Pagination,
  PublishArticle,
  SearchInput,
} from '@app/shared'
import { ARTICLES_KEY } from '@app/hooks/query/articles'
import { Link } from 'react-router-dom'
import { ArticlesService } from '@app/api'
import { privateRoutes } from '@app/routes'
import { ColumnsType } from 'antd/es/table'
import { IArticleTag } from '@app/interfaces/articleTags'
import { ICroppedCategory } from '@app/interfaces/category'

interface props {
  articles: IArticles[]
  pagination: IPagination
}

const ArticlesTable: React.FC<props> = ({ articles, pagination }) => {
  const tableTitle = () => <SearchInput fetchingQueryKey={ARTICLES_KEY} />
  const tableFooter = () => <Pagination pagination={pagination} fetchingQueryKey={ARTICLES_KEY} />

  const columns: ColumnsType<IArticles> = [
    {
      title: 'ID',
      dataIndex: 'id',
      render: (text: idType) => <span># {text}</span>,
    },
    {
      title: 'Заголовок',
      dataIndex: 'title',
      render: (text: string, record: IArticles) => (
        <Link to={privateRoutes.articles.path + '/details/' + record.id}>{text}</Link>
      ),
    },
    {
      title: 'Категории',
      dataIndex: 'categories',
      render: (categories: ICroppedCategory[]) =>
        categories.map((category) => (
          <CategoryLink key={category.id} title={category.title} id={category.id} />
        )),
    },
    {
      title: 'Категория (устаревшее)',
      dataIndex: 'category',
      render: (category: ICroppedCategory) =>
        category && <CategoryLink id={category.id} title={category.title} />,
    },
    {
      title: 'Дата публикации',
      dataIndex: 'date',
    },
    {
      title: 'Теги',
      dataIndex: 'tags',
      render: (tags: IArticleTag[]) => <ArticleTags tags={tags} />,
    },
    {
      title: 'Статус',
      dataIndex: 'status',
      render: (status) => <ArticleStatus status={status} />,
    },
    {
      title: 'Действия',
      key: 'actions',
      align: 'right',
      render: (record: IArticles) => (
        <Space>
          <PublishArticle
            id={record.id}
            status={record.status}
            queryKey={ARTICLES_KEY}
            api={ArticlesService.publish}
          />
          <EditLink id={record.id} route={'articles'} />
          <Divider type={'vertical'} />
          <DeleteModal
            deleteFunction={ArticlesService.delete}
            id={record.id}
            invalidateQueryKey={ARTICLES_KEY}
          />
        </Space>
      ),
    },
  ]

  return (
    <Table
      bordered
      rowKey={'id'}
      columns={columns}
      dataSource={articles}
      pagination={false}
      scroll={{ x: true }}
      title={tableTitle}
      footer={tableFooter}
    />
  )
}

export default ArticlesTable
