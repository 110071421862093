import { idType } from '@app/interfaces'
import makeRequest from '@api/makeRequest'
import queryString from 'query-string'
import { history } from '@api/history'
import { INews } from '@app/interfaces/news'

const url = '/api/news'

class NewsService {
  getAll() {
    return makeRequest({
      url,
      params: queryString.parse(history.location.search),
    })
  }

  getById(id: idType) {
    return makeRequest({
      url: url + '/' + id,
    })
  }

  create(data: INews) {
    return makeRequest({
      url,
      method: 'post',
      headers: {
        'Content-Type': 'multipart/form-data; boundary=something',
      },
      data,
    })
  }

  delete(id: idType) {
    return makeRequest({
      url: url + '/' + id,
      method: 'delete',
    })
  }

  update(data: { news: INews; id: idType }) {
    return makeRequest({
      url: url + '/' + data.id + '?_method=PUT',
      method: 'post',
      data: data.news,
    })
  }

  publish(id: idType) {
    return makeRequest({
      url: url + '/' + id + '/publish',
      method: 'post',
    })
  }
}

export default new NewsService()
